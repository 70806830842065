<template>
  <div>
    <Row class="m-b-5">
      <i-col span="8">
        <i-input
          size="small"
          v-model="query.keyWords"
          placeholder="关键字：品牌名称"
        ></i-input>
      </i-col>
      <i-col span="16">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="reloadData"
          class="m-l-5"
          >搜索</Button
        >
      </i-col>
    </Row>

    <i-table size="small" stripe :data="tableData" :columns="tableColumns" :loading="loading"></i-table>

    <div class="paging_style">
        <Page size="small" show-total show-elevator :current="query.pageNumber" :page-size="query.pageSize" :total="total" @on-change="handlePageNumberChange"></Page>
    </div>
  </div>
</template>

<script>
import { getAllBrands } from '@/api/crm/brand'

export default {
  data () {
    return {
      tableData: [],
      tableColumns: [
        { title: '品牌名称', key: 'name' },
        { title: '所属客户', key: 'advertiserName' },
        { title: '所属行业', key: 'industryName' },
        { title: '关联平台品牌', key: 'platformBrandName' },
        { title: '关联品牌行业', key: 'platformIndustryName' }
      ],
      query: {
        keyWords: null,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        status: '2,3,5',
        pageNumber: 1,
        pageSize: 20,
        companyId: (this.$store.getters.token.userInfo.companyId === this.$store.getters.token.userInfo.publisherId) ? null : this.$store.getters.token.userInfo.companyId,
        userId: this.isAuth('Advertiser_Manage_View') ? null : this.$store.getters.token.userInfo.userId
      },
      total: 0,
      loading: false
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.loading = true
      getAllBrands(this.query).then(res => {
        this.tableData = res.list
        this.total = res.totalRow

        this.loading = false
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    }
  }
}
</script>
